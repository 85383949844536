var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',[_c('DeletePopupCommon',{attrs:{"title":"администратора","name":_vm.fullName,"dialog":_vm.dialog,"delete-function":_vm.deleteAdmin},on:{"closeDeletePopup":_vm.closeDeletePopup}}),[_c('div',{staticClass:"keep__title"},[_c('p',{staticClass:"keep__text"},[_vm._v(" "+_vm._s(_vm.sourceObject.results ? _vm.sourceObject.results.name : "")+" ")]),(
          _vm.sourceObject &&
            _vm.sourceObject.results &&
            _vm.sourceObject.results.acquiring_provider === 'sberbank' &&
            (_vm.sourceObject.results.acquiring_entity === 'self' ||
              (_vm.sourceObject.results.type === 'diller' &&
                _vm.sourceObject.results.acquiring_entity === 'dealer'))
        )?_c('router-link',{staticClass:"btn btn_color_grey btn-edit",attrs:{"to":"/EditAcquiring"}},[_vm._v("Настройки Эквайринга")]):_vm._e()],1)],_c('SlideLayout',{attrs:{"tabs":_vm.slideTabs},scopedSlots:_vm._u([{key:"info",fn:function(){return [(_vm.sourceObject !== null)?_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.GetDataDeals},scopedSlots:_vm._u([{key:"item.2.value",fn:function({ item }){return [(_vm.sourceObject.results && _vm.sourceObject.results.contract)?_c('a',{attrs:{"download":"","href":item.value}},[_vm._v(_vm._s(`Договор № ${_vm.sourceObject.results.contract.number}`))]):_c('span',[_vm._v("-")])]}}],null,false,1482163334)}):_vm._e()]},proxy:true},{key:"recv",fn:function(){return [(_vm.sourceObject !== null)?_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.getDataRequisites}}):_vm._e()]},proxy:true},{key:"admins",fn:function(){return [_c('div',{staticClass:"keep__title",staticStyle:{"float":"right"}},[_c('router-link',{staticClass:"btn btn-add btn_color_green",attrs:{"to":"/createAdmin","append":""}},[_vm._v("Добавить Администратора")])],1),_c('BasicTable',{attrs:{"header":_vm.headers,"table-data":_vm.getDataAdmins.results,"deleteButtonDirectionStart":""},scopedSlots:_vm._u([{key:"delete-td-start",fn:function({ itemChild }){return [_c('td',{staticClass:"btn-delete-wrap"},[_c('button',{staticClass:"form__btn-delete btn btn-delete",staticStyle:{"background-color":"#eb5c6d"},on:{"click":function($event){return _vm.clickDelete(itemChild)}}})])]}}])})]},proxy:true}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }