<template>
  <MainLayout>
    <!-- popup подтверждающий удаление -->
    <DeletePopupCommon
      title="администратора"
      :name="fullName"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteAdmin"
    />
    <!-- /popup подтверждающий удаление-->
    <template>
      <div class="keep__title">
        <p class="keep__text">
          {{ sourceObject.results ? sourceObject.results.name : "" }}
        </p>
        <router-link
          to="/EditAcquiring"
          v-if="
            sourceObject &&
              sourceObject.results &&
              sourceObject.results.acquiring_provider === 'sberbank' &&
              (sourceObject.results.acquiring_entity === 'self' ||
                (sourceObject.results.type === 'diller' &&
                  sourceObject.results.acquiring_entity === 'dealer'))
          "
          class="btn btn_color_grey btn-edit"
          >Настройки Эквайринга</router-link
        >
      </div>
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <BasicTableWithoutHeader
          :table-data="GetDataDeals"
          v-if="sourceObject !== null"
        >
          <template v-slot:item.2.value="{ item }">
            <a
              v-if="sourceObject.results && sourceObject.results.contract"
              download
              :href="item.value"
              >{{ `Договор № ${sourceObject.results.contract.number}` }}</a
            >
            <span v-else>-</span>
          </template>
        </BasicTableWithoutHeader>
      </template>
      <template v-slot:recv>
        <BasicTableWithoutHeader
          :table-data="getDataRequisites"
          v-if="sourceObject !== null"
        />
      </template>
      <template v-slot:admins>
        <div style="float: right" class="keep__title">
          <router-link
            to="/createAdmin"
            append
            class="btn btn-add btn_color_green"
            >Добавить Администратора</router-link
          >
        </div>

        <BasicTable
          :header="headers"
          :table-data="getDataAdmins.results"
          deleteButtonDirectionStart
        >
          <template v-slot:delete-td-start="{ itemChild }">
            <td class="btn-delete-wrap">
              <button
                @click="clickDelete(itemChild)"
                class="form__btn-delete btn btn-delete"
                style="background-color: #eb5c6d"
              ></button>
            </td>
          </template>
        </BasicTable>
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTable from "@/components/tables/BasicTable";
import DeletePopupCommon from "@/components/popup/DeletePopupCommon";
import moment from "moment";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "AboutCompany",
  components: {
    BasicTable,
    SlideLayout,
    BasicTableWithoutHeader,
    MainLayout,
    DeletePopupCommon
  },
  data() {
    return {
      dialog: false, // диалоговое окно popup удаления
      fullName: "", //
      idAdmin: "", // id Админа

      headers: [
        {
          renderFunction: value => {
            return `<div>${value.last_name} ${value.first_name} ${value.middle_name}</div>`;
          },
          text: "ФИО"
        },
        {
          renderFunction: value => phoneFilter.execute(value?.phone_number),
          text: "Телефон"
        }
      ],
      slideTabs: [
        {
          text: "Договор и оплата",
          value: "info"
        },
        {
          text: "Администраторы",
          value: "admins"
        },
        {
          text: "Реквизиты",
          value: "recv"
        }
      ]
    };
  },
  computed: {
    GetDataDeals() {
      return [
        {
          text: " Дилер",
          value: this.sourceObject.results?.diller_details
            ? this.sourceObject.results?.diller_details.name
            : "-"
        },
        {
          text: "Номер договора",
          value: this?.sourceObject?.results?.contract?.number || "-"
        },
        {
          text: "Файл (скан) договора",
          value: this.sourceObject?.results?.contract?.file || "#"
        },
        {
          text: "Дата договора",
          value: this.sourceObject?.results?.contract
            ? moment(this.sourceObject.results.created_at * 1000).format(
                "DD.MM.YYYY"
              )
            : "-"
        },
        {
          text: "Количество оплаченных/зарегистрированных пользователей",
          value: `${this.sourceObject?.results?.users_count}/${
            this.sourceObject?.results?.contract?.users_limit
              ? this.sourceObject?.results?.contract?.users_limit
              : "-"
          }`
        },
        {
          text: "Количество объектов",
          value: this.sourceObject?.results?.objects_count
        },

        {
          text: "Тип оплаты",
          value:
            dictionariesHelper.acquiringEntity[
              this?.sourceObject?.results?.acquiring_entity
            ]
        },
        {
          text: "Эквайринг",
          value:
            dictionariesHelper.acquiringProvider[
              this?.sourceObject?.results?.acquiring_provider
            ]
        }
      ];
    },
    getDataAdmins() {
      return this.$store.getters.getAdmins;
    },
    sourceObject() {
      return this.$store.getters.getDealerInfo;
    },
    getDataRequisites() {
      return [
        {
          text: "Полное название организации",
          value: this.sourceObject.results?.full_org_name
        },
        {
          text: "Краткое название организации",
          value: this.sourceObject.results?.name
        },
        {
          text: "Город",
          value: this.sourceObject.results?.city_details.name
        },
        {
          text: "Тип организации",
          value: this.sourceObject.results?.ownership
        },
        {
          text: "Руководитель",
          value: this.sourceObject.results?.director
        },
        {
          text: "ИНН",
          value: this.sourceObject.results?.TIN
        },
        {
          text: "ОРГН",
          value: this.sourceObject.results?.PSRN
        },
        {
          text: "БИК",
          value: this.sourceObject.results?.RCBIC
        },
        {
          text: "КПП",
          value: this.sourceObject.results?.IEC
        },
        {
          text: "Название банка",
          value: this.sourceObject.results?.bank
        },
        {
          text: "К/c",
          value: this.sourceObject.results?.CA
        },
        {
          text: "Р/c",
          value: this.sourceObject.results?.giro
        },
        {
          text: "Адрес организации",
          value: this.sourceObject.results?.address
        },
        {
          text: "Фактический адрес организации",
          value: this.sourceObject.results?.actual_address
        },
        // {
        //   text: "Номер договора",
        //   value: this.sourceObject.results.contract
        //     ? this.sourceObject.results.contract.number
        //     : "-"
        // },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.results?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.results?.email
        }
      ];
    },
    urlQuery() {
      return {
        // id: this.$store.getters.getCurrentOrganization,
        id: this.$store.getters.getCurrentOrganization,
        query: this.$route.query
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchDealerInfo").finally(() => {
      this.isDateLoaded = false;
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchAdmins").finally(() => {
      this.isDateLoaded = false;
    });
  },

  methods: {
    clickDelete(obj) {
      this.fullName = `${obj.last_name} ${obj.first_name} ${obj.middle_name}`;
      this.idAdmin = obj.id;
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteAdmin() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteAdmin", {
          id: this.idAdmin,
          organizationId: this.$store.getters.getCurrentOrganization
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.admin)
          );
        })
        .finally(() => {
          this.$store.dispatch("fetchAdmins").finally(() => {
            this.isDateLoaded = false;
          });
        });
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchDealerInfo").finally(() => {
        this.isDateLoaded = false;
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchAdmins").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  }
};
</script>

<style scoped></style>
